window.updateFlashError = function(message) {
  header = document.querySelector('header')
  existingErrorElement = header.querySelector('#error-flash')
  if (!!existingErrorElement) {
    existingErrorElement.innerHTML = message
  } else {
    errorDiv = document.createElement("div")
    errorDiv.classList.add('p-3', 'text-red', 'bg-red-lightest')
    errorDiv.id = 'error-flash'
    errorDiv.innerHTML = message
    header.appendChild(errorDiv)
  }
}
